<template>
  <div>
    <div v-if="loading" class="loadingBox">
      <b-spinner label="Large Spinner"></b-spinner>
    </div>
    <div v-else class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Configurações da conta
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1"
            >Modifique aqui as configuraçõs da sua conta.</span
          >
        </div>
      </div>

      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Nome</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              ref="name"
              v-model="user.name"
            />
          </div>
        </div>
        <br />

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                type="text"
                class="form-control form-control-lg form-control-solid"
                ref="email"
                v-model="user.email"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Senha</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                type="password"
                class="form-control form-control-lg form-control-solid"
                ref="password"
                v-model="password"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Repetir senha</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <input
                type="password"
                class="form-control form-control-lg form-control-solid"
                ref="password_confirmation"
                v-model="password_confirmation"
              />
            </div>
          </div>
        </div>

        <div class="card-header pt-10 pl-0 pb-0" v-if="user.is_store">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              Dados da ótica
            </h3>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 20px;
          "
        >
          <div class="form-group row mb-10" v-if="user.is_store && store">
            <label class="col-xl-3 col-lg-3 col-form-label">CNPJ</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="document"
                  v-model="store.document"
                  v-mask="['##.###.###/####-##']"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-10" v-if="user.is_store">
            <label class="col-xl-3 col-lg-3 col-form-label">Nome</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="storeName"
                  v-model="store.name"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="user.is_store">
            <label class="col-xl-3 col-lg-3 col-form-label">CEP</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="zip"
                  v-model="store.address.zip"
                  v-mask="['#####-###']"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="user.is_store">
            <label class="col-xl-3 col-lg-3 col-form-label">Rua</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="street"
                  v-model="store.address.street"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="user.is_store">
            <label class="col-xl-3 col-lg-3 col-form-label">Cidade</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="city"
                  v-model="store.address.city"
                />
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="user.is_store">
            <label class="col-xl-3 col-lg-3 col-form-label">Estado</label>
            <div class="col-lg-9 col-xl-6">
              <div class="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  ref="state"
                  v-model="store.address.state"
                />
              </div>
            </div>
          </div>

          <div class="form-group mt-10" v-if="user.is_store">
            <image-uploader
              :debug="1"
              :maxWidth="512"
              :quality="0.7"
              :autoRotate="true"
              outputFormat="verbose"
              :preview="false"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              capture="environment"
              accept="video/*,image/*"
              doNotResize="['gif', 'svg']"
              @input="setImage"
              style="display: none"
              ref="uploader"
            >
              <label for="fileInput" slot="upload-label">
                <figure>
                  <image :src="image" />
                </figure>
              </label>
              <span class="upload-caption">{{
                hasImage ? 'Replace' : 'Click to upload'
              }}</span>
            </image-uploader>
            <button
              type="button"
              id="kt_login_signup_cancel"
              class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              @click="openUpload"
            >
              Anexar logotipo
            </button>
            <div class="preview" v-if="image">
              <img :src="image" style="width: 100%" />
            </div>
            <p
              class="text-muted font-weight-bold font-size-h4"
              style="margin-top: 20px"
            >
              <strong class="text-dark">Cor tema</strong>
              Escolha pelo espectro de cor a ou insira a cor Hexadecimal no box
              abaixo. Você pode editar essas informações a qualquer momento pelo
              menu "Meu Perfil
            </p>
          </div>

          <div class="form-group row" v-if="user.is_store">
            <div class="col-lg-9 col-xl-6">
              <ColorPicker
                :width="300"
                :height="300"
                :disabled="false"
                :startColor="color"
                @colorChange="onColorChange"
                style="margin: 0 auto"
              ></ColorPicker>
              <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                type="text"
                v-model="color"
                autocomplete="off"
                style="margin-top: 20px"
              />
            </div>
          </div>
        </div>
        <LaboratoryProfile />

        <ImageList v-if="user.is_store || user.is_salesman" />
      </div>

      <div class="card-footer">
        <div class="card-toolbar">
          <button
            type="reset"
            class="btn btn-success mr-2"
            @click="save"
            ref="kt_save_changes"
          >
            Salvar
          </button>
          <button
            type="reset"
            class="btn btn-secondary"
            @click="$router.push('/dashboard')"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from 'vue-color-picker-wheel'
import { mask } from 'vue-the-mask'
import { UPDATE_ME } from '@/core/services/store/auth.module'

export default {
  name: 'Profile',

  components: { ColorPicker },

  directives: { mask },

  data: () => ({
    color: '#ff0000',
    loading: false,
    image: null,
    hasImage: true,
    password: null,
    password_confirmation: null
  }),

  computed: {
    user: {
      get() {
        return this.$store.state.auth.user
      },
      set(user) {
        this.$store.dispatch('auth/setUser', user)
      }
    },
    store: {
      get() {
        return this.$store.state.auth.store
      },
      set(store) {
        this.$store.dispatch('auth/setStore', store)
      }
    }
  },

  methods: {
    save() {
      this.loading = true
      const obj = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        color: this.color,
        user: this.user,
        store: this.store,
        logo: this.image
      }
      this.$store.dispatch(UPDATE_ME, obj).then(() => (this.loading = false))
    },

    onColorChange(color) {
      this.color = color
    },

    openUpload() {
      document.getElementById('fileInput').click()
    },

    setImage(val) {
      this.image = val.dataUrl
    }
  },

  mounted() {
    if (this.$store.state.auth.store) {
      this.image = this.$store.state.auth.store.logo
      this.color = this.store.color
    }
  }
}
</script>

<style scoped lang="css">
.form-group {
  margin-bottom: 0px !important;
}
.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 98px;
}
</style>
